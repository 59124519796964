import "./App.css";
import{RButton} from "./components/RButton";
import {GrAndroid, GrApple, GrInstagram, GrMailOption} from "react-icons/gr";
import { FaRegCopyright } from "react-icons/fa";
import { MdDesignServices } from "react-icons/md";
import { useState } from "react";

function App() {

  const [logo] = useState("/logo-blue.png");
  
  const scrollToDiv = (nomeDiv: string) => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const targetDiv = document.getElementById(nomeDiv);
    const yOffset = -160;
    const y = targetDiv!.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <div className="h-full w-full bg-ruby-white text-ruby-blue overflow-hidden">
      
          {/* title/menu */}
          <div id="div-menu" className={ "fixed top-0 left-0 right-0 flex z-50 bg-ruby-blue text-ruby-white items-center justify-between pr-3 lg:px-10 lg:py-5 "} >

            <a onClick={scrollToDiv("inicio")} href="inicio" id="menu-logo">
              <img id="logo" className="h-[100px] lg:w-[250px] lg:h-[120px] rounded object-cover transition-all duration-1000 ease-in-out" src={logo} alt="" />
            </a>

            {/* links */}
            <div className="flex gap-3 lg:text-xl">
              <a href="#saiba-mais" onClick={scrollToDiv("saiba-mais")}>Quem somos</a>
              <a href="#servicos" onClick={scrollToDiv("servicos")}>Serviços</a>
              <a href="#contato" onClick={scrollToDiv("contato")}>Contato</a>
            </div>
            
          </div>

          {/* inicio */}
          <div id="inicio" className="flex flex-col gap-5 p-5 lg:p-20 text-lg mt-[120px]">

            <div className="flex flex-col">
              <p className="font-semibold text-2xl">Venha transformar a sua empresa</p>
              <p className="font-light text-xl">Aqui é o lugar onde tudo é possível, transforme suas ideias em realidade.</p>
            </div>
            
            <div className="flex gap-3 items-center justify-center lg:justify-normal">
              <a className="hidden sm:inline-block " href="#saiba-mais" onClick={scrollToDiv("saiba-mais")}><RButton name="saiba-mais" text="Saiba Mais" /></a>
              <a className="hidden sm:inline-block " onClick={scrollToDiv("contato")} href="#contato">Entre em contato</a>
            </div>          
          
          </div>
      
          {/* mocks */}
          <div className="flex flex-col justify-center items-center px-5">
            <img  className="w-2/3" src="/ruby-mock.png" alt="" />
          </div>

          {/* saiba-mais */}
          <div  id="saiba-mais" className="h-[900px] mt-10 text-xl px-10 transition-all duration-1000 ease-in-out">
            <div className="flex flex-col justify-center items-center">
              <p className=" font-semibold mt-10 mb-10 text-2xl lg:text-4xl">Quem somos?</p>
              <p className="text-center">Bem-vindo à Ruby Studio, uma empresa de inovação onde a tecnologia se encontra com a criatividade para moldar o futuro.
              Somos apaixonados por transformar ideias em realidade, 
              combinando Design Gráfico com Desenvolvimento de Software, estamos unidos por uma missão em comum: 
              <b > impulsionar a visibilidade e o engajamento da sua marca a novos patamares.</b></p>
            </div>
              
            <div className="flex justify-center">
              <img className="lg:w-1/2 rounded-xl mt-10" src="/ruby-about.png" alt="" />
            </div>
            
          </div>

          {/* servicos */}
          <div id="servicos" className=" transition-all duration-1000 ease-in-out">
            
            <p className="flex justify-center font-semibold text-2xl lg:text-4xl">Nossos serviços</p>
            
            <div className="flex flex-col lg:flex-row justify-between gap-20 m-10 text-xl">

              <div id="service-app" className="flex flex-col justify-center items-center gap-10 border bg-ruby-white text-ruby-blue border-ruby-white shadow-xl rounded-xl  lg:h-[350px]  ">
                <p className="text-4xl font-light">Aplicativos</p>
                <div className="relative flex text-4xl justify-center items-center right-[35px] pt-5">
                  <div className="absolute bg-ruby-blue rounded-full p-5 z-0 ">
                    <GrAndroid size={50} className="text-ruby-white"/>
                  </div>
                  <div className="absolute bg-ruby-blue rounded-full p-5 z-10 shadow-xl left-5 ">
                    <GrApple size={50} className="text-ruby-white"/>
                  </div>
                </div>
                <div className="px-10 py-5 text-center">
                  <p>Desenvolvemos aplicativos móveis personalizados para Android e iOS, transformando sua empresa em uma experiência digital sob medida e eficientes.</p>
                </div>
              </div>

              <div id="service-design" className="flex flex-col justify-center items-center border bg-ruby-white text-ruby-blue rounded-xl border-ruby-white shadow-xl   lg:h-[350px]  ">
                <p className="text-4xl font-light">Design Gráfico</p>
                <div className=" flex text-4xl justify-center items-center right-[65px] pt-5 ">
                  <div className=" bg-ruby-blue rounded-full p-5 z-0 shadow-xl">
                    <MdDesignServices size={50} className="text-ruby-white"/>
                  </div>                  
                </div>
                <div className="px-10 py-5 text-center">
                  <p>Nossa equipe oferece soluções de Design Gráfico, como Identidade Visual, Design Social Media, e Design para Impressos. Cada peça de conteúdo é criada especificamente para sua marca, refletindo sua identidade e valores.</p>
                </div>
              </div>

              <div id="service-web" className="flex flex-col justify-center items-center border bg-ruby-white text-ruby-blue rounded-xl border-ruby-white shadow-xl lg:h-[350px]  ">
                <p className="text-4xl font-light">Sites</p>
                <div className=" flex text-4xl justify-center items-center right-[65px] pt-5">
                  <div className=" bg-ruby-blue rounded-full px-4 py-6 z-0 shadow-xl">
                    <p className="font-semibold text-ruby-white">{`</>`}</p>
                  </div>                  
                </div>
                <div className="px-10 py-5 text-center">
                  <p>Oferecemos serviços de Desenvolvimento Web personalizado para criar sites e aplicações online que atendam às necessidades exclusivas do seu negócio.</p>
                </div>
              </div>

            </div>
            
          </div>

          <div id="nossos-clientes py-10">
              <p className="text-2xl lg:text-4xl font-semibold flex justify-center">Nossos clientes</p>
              

              <div className="flex  justify-between  px-10 gap-3 py-3 lg:py-10">
                <img src="/clientes/thais-lash.png" className="rounded-xl w-1/6" alt="Logo Thais Silveira Lash Designer" />
                <img src="/clientes/julianaflores.png" className="rounded-xl w-1/6" alt="Logo Juliana Flores Veterinária" />
                <img src="/clientes/petshop4.png" className="rounded-xl w-1/6" alt="Logo 4 patas petshop" />
                <img src="/clientes/lojaescencial.png" className="rounded-xl w-1/6" alt="Loja Loja Escencial Bagé" />
                <img src="/clientes/naiarajoias.png" className="rounded-xl w-1/6" alt="Logo Naiara Joias" />
              </div>


              <div>

              </div>
          </div>

          {/* contato */}
          <div id="contato" className="flex flex-col transition-all duration-1000 ease-in-out">
            
            {/* contato:title-subtitle */}
            <div className="flex flex-col gap-2">
              <p className="flex justify-center font-semibold mt-10 text-2xl lg:text-4xl">Contato</p>
              <p className="flex justify-center lg:text-2xl px-5">Entre em contato conosco hoje para começar sua jornada de modernização tecnológica. Estamos ansiosos para trabalhar com você!</p>
            </div>
            
            {/* email-instagram */}
            <div className="flex flex-col lg:flex-row justify-center gap-10 mt-10 ">
              <div className="flex flex-col gap-1 justify-center items-center ">
                <GrMailOption  size={30} />
                <p className="text-2xl font-light">contatorubystudio.art@gmail.com</p>
              </div>
                
              <div className="flex flex-col gap-1 justify-center items-center">
                <GrInstagram size={30} />
                <p className="text-2xl font-light">@studioruby.art</p>
              </div>
            </div>

          </div>

          {/* footer */}
          <footer className="flex flex-col gap-1 items-center justify-center mt-[100px] ">
            <img className="w-[120px] object-cover" src="/logo-white.png" alt="" />
            <div className="flex gap-1 items-center">
              <p>Copyright </p> <FaRegCopyright/> <p>Ruby Studio</p>
            </div>
            
          </footer>
    </div>
  );
}

export default App;
