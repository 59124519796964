import React from "react";

interface RButtonProps{
    name: string;
    text: string;
    onClick?: ()=>void;
}

export function RButton(props:RButtonProps){
    return(
            <button
            className="rounded-lg bg-ruby-blue hover:bg-ruby-semiblue text-ruby-white hover:text-zinc-900 font-semibold p-3  uppercase"
            onClick={props.onClick}
             name={props.name}>
                {props.text}
            </button>
    )
}